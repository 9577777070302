import React from 'react';
import './GrowYourBussion.css';
import user from '../../assets/images/user-dil.svg';
import bag from '../../assets/images/badge-check.svg';
import shield from '../../assets/images/shield-check.svg';
import smile from '../../assets/images/smile.svg';
import mobile from '../../assets/images/mobile-1.png';

const GrowYourBussion = () => {
	return (
		<div className="GrowYourBussionWrapper">
			<div className="container">
				<div className="GrowYourBussion-wrapper ">
					<div className="top-wrapper flex gap-30 justify-center">
					<div className="left self-center">
							<img src={mobile} alt="mobile" />
						</div>
						<div className="right">
							<h2 className="heading-2">
							
							Recharges & Bill Payments
							</h2>
							<p className="h3">
							Lightning fast payments, exciting rewards and seamless transactions on every recharge & bill payment.
							</p>

						    <p style={{fontSize:"15px", fontWeight:"400"}}>
							We provide digital platform to process transaction to our client. We offer better success rates, relationships and business networks that will help further grow and augment your business volumes. We facilitate seamless transactions with a multitude of entrepreneurs and businesses, and aim to sustain and strengthen, develop and innovate digital payment services in India.
							</p>
						</div>
						
					</div>

					<div className="middle-wrapper">
						<h2 className="heading-2 text-center">
						
							Trusted, <span className="strong">Safe & Secure </span>
						</h2>
						<p style={{maxWidth:"600px",marginBottom:"20px", marginLeft: "auto", marginRight: "auto"}}>End-to-end payment solutions for businesses. Enables acceptance of payments by UPI, Cards, Digital Wallets, Net Banking and 100+ other channels</p>
						<div className="center gap-30">
							<div className="box">
								<div className="img center">
									<img src={bag} alt="shield" />
								</div>
								<h2>100%</h2>
								<p>Success Transaction</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={user} alt="user" />
								</div>
								<h2>200+</h2>
								<p>Total Retailers</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={shield} alt="shield" />
								</div>
								<h2>100%</h2>
								<p>Secure Payment</p>
							</div>
							<div className="box box-4">
								<div className="img center">
									<img src={smile} alt="smile" />
								</div>
								<h2>1.5 Lac</h2>
								<p>Happy Customers</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GrowYourBussion;
